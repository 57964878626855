const campaignColumns = [
  {
    key: 'DateStats',
    label: 'Date',
    show: true,
    headerTitle: 'Date',
    sortable: true,
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'LogType',
    label: 'Type',
    show: false,
    headerTitle: 'Type',
    sortable: true,
    thStyle: 'width:80px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'AdvertiserId',
    label: 'Advertiser ID',
    show: false,
    headerTitle: 'Advertiser ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'AdvertiserName',
    label: 'Advertiser Name',
    show: false,
    headerTitle: 'Advertiser Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'BanneradId',
    label: 'Banner Ad ID',
    show: false,
    headerTitle: 'Banner Ad ID',
    sortable: true,
    thStyle: 'width:115px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'BanneradName',
    label: 'Banner Ad Name',
    show: true,
    headerTitle: 'Banner Ad Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'VideoadId',
    label: 'Video Ad ID',
    show: false,
    headerTitle: 'Video Ad ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'VideoadName',
    label: 'Video Ad Name',
    show: true,
    headerTitle: 'Video Ad Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'NativeadId',
    label: 'Native Ad ID',
    show: false,
    headerTitle: 'Native Ad ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'NativeadName',
    label: 'Native Ad Name',
    show: true,
    headerTitle: 'Native Ad Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RemotertbzoneId',
    label: 'Rmt. RTB Zone ID',
    show: false,
    headerTitle: 'Rmt. RTB Zone ID',
    sortable: true,
    thStyle: 'width:140px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RemotertbzoneName',
    label: 'Rmt. RTB Zone Name',
    show: true,
    headerTitle: 'Rmt. RTB Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RemotevideozoneId',
    label: 'Rmt. Video Zone ID',
    show: false,
    headerTitle: 'Rmt. Video Zone ID',
    sortable: true,
    thStyle: 'width:150px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RemotevideozoneName',
    label: 'Rmt. Video Zone Name',
    show: true,
    headerTitle: 'Rmt. Video Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'DsppopfeedId',
    label: 'DSP Pop Feed ID',
    show: false,
    headerTitle: 'DSP Pop Feed ID',
    sortable: true,
    thStyle: 'width:130px',
    tdClass: 'text-right',
  },
  {
    key: 'DsppopfeedName',
    label: 'DSP Pop Feed Name',
    show: true,
    headerTitle: 'DSP Pop Feed Name',
    sortable: false,
  },
  {
    key: 'PublisherId',
    label: 'Publisher ID',
    show: false,
    headerTitle: 'Publisher ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'PublisherName',
    label: 'Publisher Name',
    show: false,
    headerTitle: 'Publisher Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'BannerzoneId',
    label: 'Banner Zone ID',
    show: false,
    headerTitle: 'Banner Zone ID',
    sortable: true,
    thStyle: 'width:130px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'BannerzoneName',
    label: 'Banner Zone Name',
    show: false,
    headerTitle: 'Banner Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'VideozoneId',
    label: 'Video Zone ID',
    show: false,
    headerTitle: 'Video Zone ID',
    sortable: true,
    thStyle: 'width:120px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'VideozoneName',
    label: 'Video Zone Name',
    show: false,
    headerTitle: 'Video Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RtbzoneId',
    label: 'RTB Zone ID',
    show: false,
    headerTitle: 'RTB Zone ID',
    sortable: true,
    thStyle: 'width:105px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RtbzoneName',
    label: 'RTB Zone Name',
    show: true,
    headerTitle: 'RTB Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'PopfeedId',
    label: 'Pop Feed ID',
    show: false,
    headerTitle: 'Pop Feed ID',
    sortable: true,
    thStyle: 'width:105px',
    tdClass: 'text-right',
  },
  {
    key: 'PopfeedName',
    label: 'Pop Feed Name',
    show: false,
    headerTitle: 'Pop Feed Name',
    sortable: false,
  },
  {
    key: 'Timestamp',
    label: 'Timestamp',
    show: true,
    headerTitle: 'Timestamp',
    sortable: true,
    class: 'text-left',
    summed: false,
  },
  {
    key: 'Ip',
    label: 'IP',
    show: true,
    headerTitle: 'IP',
    sortable: true,
    class: 'text-left',
    summed: false,
  },
  {
    key: 'RealIp',
    label: 'Real IP',
    show: true,
    headerTitle: 'Real IP',
    sortable: true,
    class: 'text-left',
    summed: false,
  },
  {
    key: 'UserAgent',
    label: 'User Agent',
    show: true,
    headerTitle: 'User Agent',
    sortable: true,
    class: 'text-left',
    summed: false,
  },
  {
    key: 'RealUserAgent',
    label: 'Real User Agent',
    show: true,
    headerTitle: 'Real User Agent',
    sortable: true,
    class: 'text-left',
    summed: false,
  },
  {
    key: 'Referer',
    label: 'Referer',
    show: true,
    headerTitle: 'Referer',
    sortable: true,
    class: 'text-left',
    summed: false,
  },
  {
    key: 'RealReferer',
    label: 'Real Referer',
    show: true,
    headerTitle: 'Real Referer',
    sortable: true,
    class: 'text-left',
    summed: false,
  },
  {
    key: 'RequestUrl',
    label: 'Request URL',
    show: true,
    headerTitle: 'Request URL',
    sortable: true,
    class: 'text-left',
    summed: false,
  },
  {
    key: 'DestinationUrl',
    label: 'Destination URL',
    show: true,
    headerTitle: 'Destination URL',
    sortable: true,
    class: 'text-left',
    summed: false,
  },
]
export default campaignColumns
